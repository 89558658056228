@mixin semiResponsive() {
    > .vd-image {
        > a {
            > img {
                max-width: 100%;
                height: auto;
            }
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
@mixin responsive() {
    width: 100%;
    > .vd-image {
        width: 100%;
        > a {
            width: 100%;
            > img {
                width: 100%;
                height: auto;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}
@mixin smallSize() {
    > .vd-image {
        img{
            width:80px;
            height:80px;
        }
    }
}
@mixin mediumSize() {
    > .vd-image {
        img{
            width:300px;
            height:94px;
        }
    }
}
@mixin largeSize() {
    > .vd-image {
        img{
            width:600px;
            height:188px;
        }
    }
}
.vd-image-container {
    display: flex;
    flex-wrap: wrap;
    &.vd-image-align-left {
        display: flex;
        justify-content: flex-start;
    }
    &.vd-image-align-center {
        display: flex;
        justify-content: center;
    }
    &.vd-image-align-right {
        display: flex;
        justify-content: flex-end;
    }
    &.vd-image-align-phone-left{
        @media screen and (max-width: 767px){
            display: flex;
            justify-content: flex-start;
        }
    }
    &.vd-image-align-phone-center{
        @media screen and (max-width: 767px){
            display: flex;
            justify-content: center;
        }
    }
    &.vd-image-align-phone-right{
        @media screen and (max-width: 767px){
            display: flex;
            justify-content: flex-end;
        }
    }
    &.vd-image-align-tablet-left{
        @media screen and (min-width: 768px) and (max-width: 992px){
            display: flex;
            justify-content: flex-start;
        }
    }
    &.vd-image-align-tablet-center{
        @media screen and (min-width: 768px) and (max-width: 992px){
            display: flex;
            justify-content: center;
        }
    }
    &.vd-image-align-tablet-right{
        @media screen and (min-width: 768px) and (max-width: 992px){
            display: flex;
            justify-content: flex-end;
        }
    }
    > .vd-image-title {
        text-align: center;
    }
    > .vd-image-wrapper {
        display: block;
        > .vd-image {
            float: left;
            img {
                display: block;
            }
            .parallax-window {
                display: block;
                background: no-repeat fixed center;
                background-size: cover;
            }
            &.vd-image-style-rounded  img {
                border-radius: 4px;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            &.vd-image-style-border {
                padding: 6px;
                background: grey;
            }
            &.vd-image-style-outline {
                border: 1px solid grey;
            }
            &.vd-image-style-shadow {
                border-radius: 0;
                -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .1);
                box-shadow: 0 0 5px rgba(0, 0, 0, .1);
            }
            &.vd-image-style-shadow_border {
                padding: 6px;
                border-radius: 0;
                -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .1);
                box-shadow: 0 0 5px rgba(0, 0, 0, .1);
            }
            &.vd-image-style-shadow_3d {
                position: relative;
                z-index: 0;
                display: inline-block;
                max-width: 100%;
                margin-bottom: 15px;
                border-radius: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
                &::before {
                    position: absolute;
                    z-index: -1;
                    right: 50%;
                    bottom: 0;
                    left: 5px;
                    height: 30%;
                    content: "";
                    -webkit-transform: skewY(-6deg);
                    -ms-transform: skewY(-6deg);
                    transform: skewY(-6deg);
                    -webkit-transform-origin: 0 0;
                    -moz-transform-origin: 0 0;
                    -ms-transform-origin: 0 0;
                    transform-origin: 0 0;
                    border-radius: inherit;
                    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, .6);
                    box-shadow: 0 15px 10px rgba(0, 0, 0, .6);
                }
                &::after {
                    position: absolute;
                    z-index: -1;
                    right: 5px;
                    bottom: 0;
                    left: 50%;
                    height: 30%;
                    content: "";
                    -webkit-transform: skewY(6deg);
                    -ms-transform: skewY(6deg);
                    transform: skewY(6deg);
                    -webkit-transform-origin: 100% 0;
                    -moz-transform-origin: 100% 0;
                    -ms-transform-origin: 100% 0;
                    transform-origin: 100% 0;
                    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, .6);
                    box-shadow: 0 15px 10px rgba(0, 0, 0, .6);
                }
            }
            &.vd-image-style-circle {
                overflow: hidden;
                border-radius: 50%;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            &.vd-image-style-border_circle {
                overflow: hidden;
                border-radius: 50%;
                background: gray;
                -webkit-box-shadow: none;
                box-shadow: none;
                img {
                    overflow: hidden;
                    border-radius: 50%;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }
            &.vd-image-style-outline_circle {
                overflow: hidden;
                border: 1px solid gray;
                border-radius: 50%;
                -webkit-box-shadow: none;
                box-shadow: none;
                img {
                    overflow: hidden;
                    border-radius: 50%;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }
            &.vd-image-style-shadow_circle {
                overflow: hidden;
                border-radius: 50%;
                -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .1);
                box-shadow: 0 0 5px rgba(0, 0, 0, .1);
            }
            &.vd-image-style-shadow_border_circle {
                overflow: hidden;
                padding: 6px;
                border-radius: 50%;
                box-shadow: 0 0 5px rgba(0, 0, 0, .1);
                -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .1);
                img {
                    border-radius: 50%;
                }
            }
        }
        &.vd-image-size-small {
            @media screen and (min-width: 992px) {
                @include smallSize();
            }
        }
        &.vd-image-size-tablet-small {
            @media screen and (min-width: 768px) and (max-width: 991px) {
                @include smallSize();
            }
        }
        &.vd-image-size-phone-small {
            @media screen and (max-width: 767px) {
                @include smallSize();
            }
        }
        &.vd-image-size-medium {
            @media screen and (min-width: 992px) {
                @include mediumSize();
            }
        }
        &.vd-image-size-tablet-medium {
            @media screen and (min-width: 768px) and (max-width: 991px) {
                @include mediumSize();
            }
        }
        &.vd-image-size-phone-medium {
            @media screen and (max-width: 767px) {
                @include mediumSize();
            }
        }
        &.vd-image-size-large {
            @media screen and (min-width: 992px) {
                @include largeSize();
            }
        }
        &.vd-image-size-tablet-large {
            @media screen and (min-width: 768px) and (max-width: 991px) {
                @include largeSize();
            }
        }
        &.vd-image-size-phone-large {
            @media screen and (max-width: 767px) {
                @include largeSize();
            }
        }
        /* Semi Responsive*/
        &.vd-image-size-semi_responsive{
            @media screen and (min-width: 992px) {
                @include semiResponsive();
            }
        }
        &.vd-image-size-phone-semi_responsive{
            @media screen and (max-width: 767px) {
                @include semiResponsive();
            }
        }
        &.vd-image-size-tablet-semi_responsive{
            @media screen and (min-width: 768px) and (max-width: 991px) {
                @include semiResponsive();
            }
        }
        &.vd-image-size-responsive{
            @media screen and (min-width: 992px) {
                @include responsive();
            }
        }
        &.vd-image-size-phone-responsive{
            @media screen and (max-width: 767px) {
                @include responsive();
            }
        }
        &.vd-image-size-tablet-responsive{
            @media screen and (min-width: 768px) and (max-width: 991px) {
                @include responsive();
            }
        }

        // &.vd-image-size-phone-responsive {
        //     @media screen and (max-width: 767px){
        //         @import "_responsive.scss";
        //     }
        // }

        // &.vd-image-size-tablet-responsive {
        //     @media screen and (min-width: 768px) and (max-width: 992px){
        //         @import "_responsive.scss";
        //     }
        // }

        // &.vd-image-size-responsive:not(.vd-image-size-tablet-responsive):not(.vd-image-size-phone-responsive) {
        //     @import "_responsive.scss";
        // }

        // &.vd-image-size-responsive.vd-image-size-tablet-responsive {
        //     @media screen and (min-width: 992px){
        //         @import "_responsive.scss";
        //     }
        // }
        // &.vd-image-size-responsive.vd-image-size-phone-responsive {
        //     @media screen and (min-width: 992px){
        //         @import "_responsive.scss";
        //     }
        // }
    }
}